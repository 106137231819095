import {Button, FloatingLabel, Form} from "react-bootstrap";
import React, {useState} from "react";
import {requestDataDeletion} from "../utils/BackendAccessLayer";

const AccountDeletion = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [response, setResponse] = useState("");
    const [email, setEmail] = useState("");
    const [deleteType, setDeleteType] = useState("");
    const [deleteAllEntries, setDeleteAllEntries] = useState(false);
    const [deleteAllPresets, setDeleteAllPresets] = useState(false);

    return (
        <div>
            <div className="row mt-5">
                <div className="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                    <div className="form-wrapper">
                        <div className="text-center">
                            <h2>Account Data Management</h2>
                            <p>Delete account or specific account data.</p>
                        </div>
                        <div>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Email address"
                                className="mb-3"
                            >
                                <Form.Control
                                    type="email"
                                    placeholder="Account email"
                                    value={email}
                                    onChange={event => setEmail(event.target.value)}
                                />
                            </FloatingLabel>
                        </div>
                        <div>
                            <div className="mb-3">
                                <Form.Check
                                    type={"radio"}
                                    label={`Delete entire account`}
                                    name={"delete-selection"}
                                    checked={deleteType === "all"}
                                    onClick={event => setDeleteType("all")}
                                />
                                <Form.Check
                                    type={"radio"}
                                    label={`Delete specific data`}
                                    name={"delete-selection"}
                                    checked={deleteType === "some"}
                                    onClick={event => setDeleteType("some")}
                                />
                                <div>
                                    {
                                        (deleteType === "some") ?
                                            <div className={"ps-5"}>
                                                <Form.Check
                                                    type={"checkbox"}
                                                    label={`Delete all entries`}
                                                    checked={deleteAllEntries}
                                                    onChange={event => {setDeleteAllEntries(!deleteAllEntries)}}
                                                />
                                                <Form.Check
                                                    type={"checkbox"}
                                                    label={`Delete all expense presets`}
                                                    checked={deleteAllPresets}
                                                    onChange={event => setDeleteAllPresets(!deleteAllPresets)}
                                                />
                                            </div> :
                                            <div></div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="d-grid gap-2">
                            <Button
                                variant="primary"
                                disabled={loading}
                                onClick={() => {
                                    requestDataDeletion(email, deleteType, deleteAllEntries, deleteAllPresets, setLoading, setError, setResponse);
                                }}
                            >
                                Submit
                            </Button>
                        </div>
                        <div className="pt-3" style={{
                            display: (response !== "") ? 'block' : 'none'
                        }}>
                            {
                                (!error) ?
                                    <div>
                                        <div className="alert alert-success" role="alert">
                                            { response }
                                        </div>
                                    </div> :
                                    <div>
                                        <div className="alert alert-danger" role="alert">
                                            { response }
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountDeletion;