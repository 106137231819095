import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { verifyAccount } from "../utils/BackendAccessLayer";
import Lottie from "react-lottie-player";
import deniedLottie from "../assets/denied.json";
import verifiedLottie from "../assets/verified.json";

const Verify = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [success, setStatus] = useState({ response: "" });

    useEffect(() => {
        verifyAccount(id, loading, setLoading, setStatus);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div class="mb-5">
            { (loading) ? 
                <div class="d-flex justify-content-center" style={{
                    marginTop: 200
                }}>
                    <div class="spinner-border text-primary" role="status" style={{
                        width: 80,
                        height: 80
                    }}>
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div> :
                <div>
                    { (success.response === "Ok") ? 
                        <div class="row">
                            <div class="col-10 offset-1 col-sm-4 offset-sm-4" style={{
                                marginTop: 100
                            }}>
                                <div>
                                    <Lottie
                                        loop={false}
                                        animationData={verifiedLottie}
                                        play
                                    />
                                </div>
                                <div class="text-center">
                                    <h2>Yay!</h2>
                                    <h4>Your account is good to go.</h4>
                                    <p>Login to your account to start using the app.</p>
                                </div>
                            </div>
                        </div> : 
                        <div class="row">
                            <div class="col-10 offset-1 col-sm-4 offset-sm-4" style={{
                                marginTop: 100
                            }}>
                                <div>
                                    <Lottie
                                        loop={false}
                                        animationData={deniedLottie}
                                        play
                                    />
                                </div>
                                <div class="text-center">
                                    <h2>Uh Oh!</h2>
                                    <h4>We encountered an error while trying to verify your account.</h4>
                                    <p>If you are copying the URL make sure it was copied correctly.</p>
                                    <p>If you have already verified your account then ignore this message.</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
};

export default Verify;