import PlayStoreBadge from "../components/PlayStoreBadge";
import RegisterForm from "../components/RegisterForm";

const Register = () => {
    
    return (
        <div>
            <div class="row mt-5">
                <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                    <h2 class="text-center">Create an Account</h2>
                    <div>
                        <PlayStoreBadge />
                    </div>
                    <RegisterForm />
                </div>
            </div>
        </div>
    );
};

export default Register;