import RegisterForm from '../components/RegisterForm';
import Menu from '../components/Menu';
import PlayStoreBadge from '../components/PlayStoreBadge';
import AppleStoreBadge from "../components/AppleStoreBadge";
import Logo from '../assets/logo.png';

const Home = () => {
    return (
        <div>
            <div>
                <Menu />
                <div class="pb-4">
                    <div class="d-flex justify-content-center">
                        <img alt={"EZ Budget"} width={80}  height={80} src={Logo} />
                        <div class="ps-4 pt-2">
                            <h4 class="text-start">Easily Plan and Manage<br/> <u>Your Budget</u></h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-5 homepage-columns">
                <div class="col-sm-4 col-10 offset-1 offset-sm-2 pb-4">
                    <RegisterForm />
                </div>
                <div class="col-sm-6 mb-sm-0">
                    <div class="ps-5 ps-sm-0 pe-5 col-sm-8">
                        <div>
                            <div>
                                <p>
                                    Create and maintain personalized budgets tailored to your unique financial needs by setting up variable and calculated expenses which allow you to easily report and track your expenses.
                                </p>
                                <p>
                                    <strong>Available for iOS and Android devices.</strong>
                                </p>
                            </div>
                            <div class="d-flex pb-3">
                                <div>
                                    <PlayStoreBadge />
                                </div>
                                <div>
                                    <AppleStoreBadge />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;