import axios from "axios";
import config from "./config";

const validatePassword = (password) => {

    const capitalLetter = /[A-Z]/;
    const digit = /[0-9]/;
    const specialCase = /[!@#$%^&*()*.?+=()_-]/;

    if(capitalLetter.test(password) && digit.test(password) && 
        specialCase.test(password) && password.length >= 8)
        return true;

    return false;
};

export const registerUser = (username, email, password, confpassword, setResponse, setError) => {

    if(username === "" || email === "" || password === "" || confpassword === "") {
        setResponse("All fields must be filled.");
        setError(true);
        return;
    }

    const validUsername = /^[a-zA-Z0-9]+$/;

    if(!validUsername.test(username)) {
        setResponse("Usernames must only contain numbers and letters.");
        setError(true);
        return;
    }

    if(!email.includes("@") || !email.includes(".")) {
        setResponse("Please enter a valid email address.");
        setError(true);
        return;
    }

    if(!validatePassword(password)) {
        setResponse("Password must be at least 8 characters long and contain: a number, an uppercase and lowercase letter, and a special character.");
        setError(true);
        return;
    }

    if(password !== confpassword) {
        setResponse("Passwords do not match.");
        setError(true);
        return;
    }

    let data = new FormData();

    data.append('email', email);
    data.append('username', username);
    data.append('password', password);

    axios.post(config.hostname + '/user/register', data)
        .then(response => {
            setResponse(response.data);
        })
        .catch(error => {
            setError(true);
            console.error(error);
            setResponse(error.response.data);
        });
};

export const verifyAccount = (code, loading, setLoading, setStatus) => {

    if(!loading)
        return;

    axios.get(config.hostname + '/user/verify/' + code)
        .then(response => {
            setLoading(false);
            setStatus({
                response: response.data
            });
        })
        .catch(error => {
            setLoading(false);
            setStatus({
                response: error.response.data
            });
        });
};

export const verifyToken = (token, setVerifyStatus, setResponse, setError) => {

    if(token == null)
        return;

    axios.get('/recaptcha/verify/' + token)
        .then(response => {
            setVerifyStatus(response.data.success);
        })
        .catch(error => {
            setResponse("An error occurred. Please reload the page.");
            setError(true);
        });
}

export const sendResetEmail = (id, setResponse, setError, setSubmitted) => {

    if(id === "") {
        setResponse("All fields required.");
        setError(true);
        return;
    }

    let data = new FormData();

    data.append('identifier', id);

    axios.post(config.hostname + '/user/password/init', data)
        .then(response => {
            console.log(response.data);
            setResponse(response.data);
            setError(false);
            setSubmitted(true);
        })
        .catch(error => {
            console.error(error.response.data);
            setResponse(error.response.data);
            setError(true);
        });
};

export const authorizePasswordCode = (code, onChange, setLoading) => {

    let data = new FormData();

    data.append('code', code);

    axios.post(config.hostname + '/user/password/auth', data)
        .then(response => {
            console.log(response.data);
            onChange(response.status === 200);
            setLoading(false);
        })
        .catch(error => {
            console.error(error.response.data);
            setLoading(false);
        });
};

export const finishPasswordChange = (newPass, confNewPass, code, setResponse, setError, setSubmitted) => {

    if(newPass === "" || confNewPass === "") {
        setResponse("All fields must be filled.");
        setError(true);
        return;
    }

    if(newPass !== confNewPass) {
        setResponse("Passwords do not match.");
        setError(true);
        return;
    }

    if(!validatePassword(newPass)) {
        setResponse("Password must be at least 8 characters long and contain: a number, an uppercase and lowercase letter, and a special character.");
        setError(true);
        return;
    }

    setSubmitted(true);

    let data = new FormData();

    data.append('password', newPass);
    data.append('code', code);

    axios.post(config.hostname + '/user/password/comp', data)
        .then(response => {
            console.log(response.data);
            setResponse(response.data);
            setError(false);
        })
        .catch(error => {
            console.error(error.response.data);
            setResponse(error.response.data);
            setError(true);
            setSubmitted(false);
        });
};

export const requestDataDeletion = (email, deleteType, deleteAllEntries, deleteAllPresets, setLoading, setError, setResponse) => {
    setLoading(true);
    setResponse("");
    setError(false);

    if(!email.includes("@") || !email.includes(".")) {
        setResponse("Please enter a valid email address.");
        setError(true);
        setLoading(false);
        return;
    }

    if(deleteType === "" || (deleteType === "some" && !deleteAllEntries && !deleteAllPresets)) {
        setResponse("Please specify data to delete.");
        setError(true);
        setLoading(false);
        return;
    }

    setTimeout(() => {
        setResponse("Request sent. Please allow 24 hours for a response from our support team.");
        setLoading(false);
    }, 1200);
};