import iOSLogo from '../assets/ios_store_badge.svg';
const AppleStoreBadge = () => {

    return (
        <div className={"pt-2"}>
            <a href='https://apps.apple.com/us/app/ez-budget/id6464128386'><img alt='Get it on iOS App Store' src={iOSLogo} class="playstore-badge" /></a>
        </div>
    );
};

export default AppleStoreBadge;