
const PrivacyPolicy = () => {

    return (
        <div class="row mt-5">
            <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
            <div class="mb-5">
                <div><h1>PRIVACY POLICY</h1></div>
                
                <div class="pb-3"><strong>Last updated June 30, 2023</strong></div>
                
                <div>This privacy notice for EZ Budget ("<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"),
                    describes how and why we might collect, store, use, and/or share ("<strong>process</strong>") your information when
                    you use our services ("<strong>Services</strong>"), such as when you:</div>
                <ul>
                    <li>Visit our website at ezbudget.app, or any website of ours that links to this privacy notice</li>
                </ul>
                <ul>
                    <li>Download and use our mobile application (EZ Budget), or any other application of ours that links to this privacy
                    notice</li>
                </ul>
                <ul>
                    <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                </ul>
                <div class="pt-3 pb-3"><strong>Questions or concerns?&nbsp;</strong>Reading this privacy notice will help you understand your privacy
                    rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you
                    still have any questions or concerns, please contact us at support@ezbudget.app.</div>
                
                
                <div class="pt-5"><h3>SUMMARY OF KEY POINTS</h3></div>
                
                <div class="pb-3"><strong><em>This summary provides key points from our privacy notice, but you can find out more details about any
                        of these topics by clicking the link following each key point or by using our&nbsp;</em></strong><a
                    href="#toc"><strong><em>table of contents</em></strong></a><strong><em>&nbsp;below to find the section you are
                        looking for.</em></strong></div>
                
                <div class="pt-3 pb-3"><strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may
                    process personal information depending on how you interact with us and the Services, the choices you make, and the
                    products and features you use. Learn more about&nbsp;<a href="#personalinfo">personal information you disclose to
                    us</a>.</div>
                <div class="pt-3 pb-3"><strong>Do we process any sensitive personal information?</strong> We may process sensitive personal information
                    when necessary with your consent or as otherwise permitted by applicable law. Learn more about&nbsp;<a
                    href="#sensitiveinfo">sensitive information we process</a>.</div>
                <div class="pt-3 pb-3"><strong>Do we receive any information from third parties?</strong> We do not receive any information from third
                    parties.</div>
                <div class="pt-3 pb-3"><strong>How do we process your information?</strong> We process your information to provide, improve, and
                    administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may
                    also process your information for other purposes with your consent. We process your information only when we have a
                    valid legal reason to do so. Learn more about&nbsp;<a href="#infouse">how we process your information</a>.</div>
                <div class="pt-3 pb-3"><strong>In what situations and with which parties do we share personal information?</strong> We may share
                    information in specific situations and with specific third parties. Learn more about&nbsp;<a href="#whoshare">when
                    and with whom we share your personal information</a>.</div>
                <div class="pt-3 pb-3"><strong>How do we keep your information safe?</strong> We have organizational and technical processes and
                    procedures in place to protect your personal information. However, no electronic transmission over the internet or
                    information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
                    cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect,
                    access, steal, or modify your information. Learn more about&nbsp;<a href="#infosafe">how we keep your information
                    safe</a>.</div>
                <div class="pt-3 pb-3"><strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy
                    law may mean you have certain rights regarding your personal information. Learn more about&nbsp;<a
                    href="#privacyrights">your privacy rights</a>.</div>
                <div class="pt-3 pb-3"><strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by submitting
                    a&nbsp;<a href="https://app.termly.io/notify/3e907a43-7fe1-4dea-be98-2284a225101e">data subject access request</a>,
                    or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
                </div>
                <div>Want to learn more about what we do with any information we collect?&nbsp;<a href="#toc">Review the privacy
                    notice in full</a>.</div>
                
                
                <div class="pt-5"><strong>TABLE OF CONTENTS</strong></div>
                <div><a href="#infocollect">1. WHAT INFORMATION DO WE COLLECT?</a></div>
                <div><a href="#infouse">2. HOW DO WE PROCESS YOUR INFORMATION?</a></div>
                <div><a href="#legalbases">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a></div>
                <div><a href="#whoshare">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></div>
                <div><a href="#inforetain">5. HOW LONG DO WE KEEP YOUR INFORMATION?</a></div>
                <div><a href="#infosafe">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</a></div>
                <div><a href="#infominors">7. DO WE COLLECT INFORMATION FROM MINORS?</a></div>
                <div><a href="#privacyrights">8. WHAT ARE YOUR PRIVACY RIGHTS?</a></div>
                <div><a href="#DNT">9. CONTROLS FOR DO-NOT-TRACK FEATURES</a></div>
                <div><a href="#uslaws">10. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></div>
                <div><a href="#otherlaws">11. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</a></div>
                <div><a href="#policyupdates">12. DO WE MAKE UPDATES TO THIS NOTICE?</a></div>
                <div><a href="#contact">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></div>
                <div><a href="#request">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></div>
                
                
                <div class="pt-5" id="infocollect"><strong>1. WHAT INFORMATION DO WE COLLECT?</strong></div>
                
                <div class="pt-3 pb-3"><strong>Personal information you disclose to us</strong></div>
                
                <div>
                    <div><strong><em>In Short:</em></strong><em>We collect personal information that you provide to us.</em></div>
                </div>
                <div>We collect personal information that you voluntarily provide to us when you register on the
                    Services,&nbsp;express an interest in obtaining information about us or our products and Services, when you
                    participate in activities on the Services, or otherwise when you contact us.</div>
                
                <div class="pt-3"><strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the
                    context of your interactions with us and the Services, the choices you make, and the products and features you use.
                    The personal information we collect may include the following:</div>
                <ul>
                    <li>email addresses</li>
                </ul>
                <ul>
                    <li>financial information</li>
                </ul>
                <div class="pt-3 pb-3"><strong>Sensitive Information.</strong> When necessary, with your consent or as otherwise permitted by applicable
                    law, we process the following categories of sensitive information:</div>
                <ul>
                    <li>financial data</li>
                </ul>
                <div class="pt-3 pb-3"><strong>Application Data.</strong> If you use our application(s), we also may collect the following information
                    if you choose to provide us with access or permission:
                    <div>
                    <div>
                        <ul>
                        <li><em>Push Notifications.</em> We may request to send you push notifications regarding your account or
                            certain features of the application(s). If you wish to opt out from receiving these types of communications,
                            you may turn them off in your device's settings.</li>
                        </ul>
                        <div>This information is primarily needed to maintain the security and operation of our application(s), for
                        troubleshooting, and for our internal analytics and reporting purposes.</div>
                        <div>All personal information that you provide to us must be true, complete, and accurate, and you must notify
                        us of any changes to such personal information.</div>
                        <div class="pt-3 pb-3"><strong>Information automatically collected</strong></div>
                        <div>
                        <div class="pt-3 pb-3"><strong><em>In Short:</em></strong><em>Some information &mdash; such as your Internet Protocol (IP)
                            address and/or browser and device characteristics &mdash; is collected automatically when you visit our
                            Services.</em></div>
                        </div>
                        <div>We automatically collect certain information when you visit, use, or navigate the Services. This
                        information does not reveal your specific identity (like your name or contact information) but may include
                        device and usage information, such as your IP address, browser and device characteristics, operating system,
                        language preferences, referring URLs, device name, country, location, information about how and when you use
                        our Services, and other technical information. This information is primarily needed to maintain the security
                        and operation of our Services, and for our internal analytics and reporting purposes.</div>
                        <div>The information we collect includes:</div>
                        <ul>
                        <li><em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage, and performance
                            information our servers automatically collect when you access or use our Services and which we record in log
                            files. Depending on how you interact with us, this log data may include your IP address, device information,
                            browser type, and settings and information about your activity in the Services&nbsp;(such as the date/time
                            stamps associated with your usage, pages and files viewed, searches, and other actions you take such as
                            which features you use), device event information (such as system activity, error reports (sometimes called
                            "crash dumps"), and hardware settings).</li>
                        </ul>
                        <ul>
                        <li><em>Device Data.</em> We collect device data such as information about your computer, phone, tablet, or
                            other device you use to access the Services. Depending on the device used, this device data may include
                            information such as your IP address (or proxy server), device and application identification numbers,
                            location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system,
                            and system configuration information.</li>
                        </ul>
                        <ul>
                        <li><em>Location Data.</em> We collect location data such as information about your device's location, which
                            can be either precise or imprecise. How much information we collect depends on the type and settings of the
                            device you use to access the Services. For example, we may use GPS and other technologies to collect
                            geolocation data that tells us your current location (based on your IP address). You can opt out of allowing
                            us to collect this information either by refusing access to the information or by disabling your Location
                            setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the
                            Services.</li>
                        </ul>
                        <div class="pt-5" id="infouse"><strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong></div>
                        <div>
                        <div class="pt-3 pb-3"><strong><em>In Short:&nbsp;</em></strong><em>We process your information to provide, improve, and
                            administer our Services, communicate with you, for security and fraud prevention, and to comply with law.
                            We may also process your information for other purposes with your consent.</em></div>
                        </div>
                        <div class="pt-3 pb-3"><strong>We process your personal information for a variety of reasons, depending on how you interact with
                            our Services, including:</strong></div>
                        <ul>
                        <li><strong>To facilitate account creation and authentication and otherwise manage user
                            accounts.&nbsp;</strong>We may process your information so you can create and log in to your account, as
                            well as keep your account in working order.</li>
                        </ul>
                        <div>
                        <div>
                            <div>
                            <div>
                                <div>
                                <div>
                                    <div>
                                    <div>
                                        <div>
                                        <div>
                                            <div>
                                            <div>
                                                <div>
                                                <div>
                                                    <div>
                                                    <div>
                                                        <div>
                                                        <div>
                                                            <div>
                                                            <div>
                                                                <div>
                                                                <div>
                                                                    <ul>
                                                                    <li><strong>To save or protect an individual's vital
                                                                        interest.</strong> We may process your information when
                                                                        necessary to save or protect an individual&rsquo;s vital
                                                                        interest, such as to prevent harm.</li>
                                                                    </ul>
                                                                    <ul>
                                                                    <li><strong>Charting and Graphing</strong><strong>.</strong> To
                                                                        provide the user with visual data of their financial information
                                                                    </li>
                                                                    </ul>
                                                                    <div class="pt-5" id="legalbases"><strong>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
                                                                        INFORMATION?</strong></div>
                                                                    <div class="pt-3 pb-3"><em><strong>In Short:&nbsp;</strong>We only process your
                                                                        personal information when we believe it is necessary and we have
                                                                        a valid legal reason (i.e., legal basis) to do so under
                                                                        applicable law, like with your consent, to comply with laws, to
                                                                        provide you with services to enter into or fulfill our
                                                                        contractual obligations, to protect your rights, or to fulfill
                                                                        our legitimate business interests.</em></div>
                                                                    <div><em><strong><u>If you are located in the EU or UK, this section
                                                                            applies to you.</u></strong></em></div>
                                                                    <div>The General Data Protection Regulation (GDPR) and UK GDPR
                                                                    require us to explain the valid legal bases we rely on in order to
                                                                    process your personal information. As such, we may rely on the
                                                                    following legal bases to process your personal information:</div>
                                                                    <ul>
                                                                    <li><strong>Consent.&nbsp;</strong>We may process your information
                                                                        if you have given us permission (i.e., consent) to use your
                                                                        personal information for a specific purpose. You can withdraw
                                                                        your consent at any time. Learn more about&nbsp;<a
                                                                        href="#withdrawconsent">withdrawing your consent</a>.</li>
                                                                    </ul>
                                                                    <ul>
                                                                    <li><strong>Performance of a Contract.</strong> We may process
                                                                        your personal information when we believe it is necessary to
                                                                        fulfill our contractual obligations to you, including providing
                                                                        our Services or at your request prior to entering into a
                                                                        contract with you.</li>
                                                                    </ul>
                                                                    <ul>
                                                                    <li><strong>Legal Obligations.</strong> We may process your
                                                                        information where we believe it is necessary for compliance with
                                                                        our legal obligations, such as to cooperate with a law
                                                                        enforcement body or regulatory agency, exercise or defend our
                                                                        legal rights, or disclose your information as evidence in
                                                                        litigation in which we are involved.</li>
                                                                    </ul>
                                                                    <ul>
                                                                    <li><strong>Vital Interests.</strong> We may process your
                                                                        information where we believe it is necessary to protect your
                                                                        vital interests or the vital interests of a third party, such as
                                                                        situations involving potential threats to the safety of any
                                                                        person.</li>
                                                                    </ul>
                                                                    <div class="pt-3 pb-3"><strong><u><em>If you are located in Canada, this section
                                                                            applies to you.</em></u></strong></div>
                                                                    <div>We may process your information if you have given us specific
                                                                    permission (i.e., express consent) to use your personal
                                                                    information for a specific purpose, or in situations where your
                                                                    permission can be inferred (i.e., implied consent). You
                                                                    can&nbsp;<a href="#withdrawconsent">withdraw your
                                                                        consent</a>&nbsp;at any time.</div>
                                                                    <div>In some exceptional cases, we may be legally permitted under
                                                                    applicable law to process your information without your consent,
                                                                    including, for example:</div>
                                                                    <ul>
                                                                    <li>If collection is clearly in the interests of an individual and
                                                                        consent cannot be obtained in a timely way</li>
                                                                    </ul>
                                                                    <ul>
                                                                    <li>For investigations and fraud detection and prevention</li>
                                                                    </ul>
                                                                    <ul>
                                                                    <li>For business transactions provided certain conditions are met
                                                                    </li>
                                                                    </ul>
                                                                    <ul>
                                                                    <li>If it is contained in a witness statement and the collection
                                                                        is necessary to assess, process, or settle an insurance claim
                                                                    </li>
                                                                    </ul>
                                                                    <ul>
                                                                    <li>For identifying injured, ill, or deceased persons and
                                                                        communicating with next of kin</li>
                                                                    </ul>
                                                                    <ul>
                                                                    <li>If we have reasonable grounds to believe an individual has
                                                                        been, is, or may be victim of financial abuse</li>
                                                                    </ul>
                                                                    <ul>
                                                                    <li>If it is reasonable to expect collection and use with consent
                                                                        would compromise the availability or the accuracy of the
                                                                        information and the collection is reasonable for purposes
                                                                        related to investigating a breach of an agreement or a
                                                                        contravention of the laws of Canada or a province</li>
                                                                    </ul>
                                                                    <ul>
                                                                    <li>If disclosure is required to comply with a subpoena, warrant,
                                                                        court order, or rules of the court relating to the production of
                                                                        records</li>
                                                                    </ul>
                                                                    <ul>
                                                                    <li>If it was produced by an individual in the course of their
                                                                        employment, business, or profession and the collection is
                                                                        consistent with the purposes for which the information was
                                                                        produced</li>
                                                                    </ul>
                                                                    <ul>
                                                                    <li>If the collection is solely for journalistic, artistic, or
                                                                        literary purposes</li>
                                                                    </ul>
                                                                    <ul>
                                                                    <li>If the information is publicly available and is specified by
                                                                        the regulations</li>
                                                                    </ul>
                                                                    <div class="pt-5" id="whoshare"><strong>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                                                                        INFORMATION?</strong></div>
                                                                    <div class="pt-3 pb-3"><strong><em>In Short:</em></strong><em>&nbsp;We may share
                                                                        information in specific situations described in this section
                                                                        and/or with the following third parties.</em></div>
                                                                    <div>We may need to share your personal information in the following
                                                                    situations:</div>
                                                                    <ul>
                                                                    <li><strong>Business Transfers.</strong> We may share or transfer
                                                                        your information in connection with, or during negotiations of,
                                                                        any merger, sale of company assets, financing, or acquisition of
                                                                        all or a portion of our business to another company.</li>
                                                                    </ul>
                                                                    <div>
                                                                    <div>
                                                                        <div>
                                                                        <div>
                                                                            <div>
                                                                            <div class="pt-5" id="inforetain"><strong>5. HOW LONG DO WE KEEP YOUR
                                                                                INFORMATION?</strong></div>
                                                                            <div class="pt-3 pb-3"><strong><em>In Short:&nbsp;</em></strong><em>We keep
                                                                                your information for as long as necessary to fulfill
                                                                                the purposes outlined in this privacy notice unless
                                                                                otherwise required by law.</em></div>
                                                                            <div>We will only keep your personal information for as
                                                                                long as it is necessary for the purposes set out in this
                                                                                privacy notice, unless a longer retention period is
                                                                                required or permitted by law (such as tax, accounting,
                                                                                or other legal requirements). No purpose in this notice
                                                                                will require us keeping your personal information for
                                                                                longer than the period of time in which users have an
                                                                                account with us.</div>
                                                                            <div>When we have no ongoing legitimate business need to
                                                                                process your personal information, we will either delete
                                                                                or anonymize such information, or, if this is not
                                                                                possible (for example, because your personal information
                                                                                has been stored in backup archives), then we will
                                                                                securely store your personal information and isolate it
                                                                                from any further processing until deletion is possible.
                                                                            </div>
                                                                            <div class="pt-5" id="infosafe"><strong>6. HOW DO WE KEEP YOUR INFORMATION
                                                                                SAFE?</strong></div>
                                                                            <div class="pt-3 pb-3"><strong><em>In Short:&nbsp;</em></strong><em>We aim
                                                                                to protect your personal information through a system
                                                                                of organizational and technical security
                                                                                measures.</em></div>
                                                                            <div>We have implemented appropriate and reasonable
                                                                                technical and organizational security measures designed
                                                                                to protect the security of any personal information we
                                                                                process. However, despite our safeguards and efforts to
                                                                                secure your information, no electronic transmission over
                                                                                the Internet or information storage technology can be
                                                                                guaranteed to be 100% secure, so we cannot promise or
                                                                                guarantee that hackers, cybercriminals, or other
                                                                                unauthorized third parties will not be able to defeat
                                                                                our security and improperly collect, access, steal, or
                                                                                modify your information. Although we will do our best to
                                                                                protect your personal information, transmission of
                                                                                personal information to and from our Services is at your
                                                                                own risk. You should only access the Services within a
                                                                                secure environment.</div>
                                                                            <div class="pt-5" id="infominors"><strong>7. DO WE COLLECT INFORMATION FROM
                                                                                MINORS?</strong></div>
                                                                            <div class="pt-3 pb-3"><strong><em>In Short:</em></strong><em>&nbsp;We do
                                                                                not knowingly collect data from or market to children
                                                                                under 18 years of age.</em></div>
                                                                            <div>We do not knowingly solicit data from or market to
                                                                                children under 18 years of age. By using the Services,
                                                                                you represent that you are at least 18 or that you are
                                                                                the parent or guardian of such a minor and consent to
                                                                                such minor dependent&rsquo;s use of the Services. If we
                                                                                learn that personal information from users less than 18
                                                                                years of age has been collected, we will deactivate the
                                                                                account and take reasonable measures to promptly delete
                                                                                such data from our records. If you become aware of any
                                                                                data we may have collected from children under age 18,
                                                                                please contact us at support@ezbudget.app.</div>
                                                                            <div class="pt-5" id="privacyrights"><strong>8. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
                                                                            </div>
                                                                            <div class="pt-3 pb-3"><strong><em>In Short:</em></strong><em>&nbsp;In some
                                                                                regions, such as the European Economic Area (EEA),
                                                                                United Kingdom (UK), and Canada, you have rights that
                                                                                allow you greater access to and control over your
                                                                                personal information.&nbsp;You may review, change, or
                                                                                terminate your account at any time.</em></div>
                                                                            <div>In some regions (like the EEA, UK, and Canada), you
                                                                                have certain rights under applicable data protection
                                                                                laws. These may include the right (i) to request access
                                                                                and obtain a copy of your personal information, (ii) to
                                                                                request rectification or erasure; (iii) to restrict the
                                                                                processing of your personal information; and (iv) if
                                                                                applicable, to data portability. In certain
                                                                                circumstances, you may also have the right to object to
                                                                                the processing of your personal information. You can
                                                                                make such a request by contacting us by using the
                                                                                contact details provided in the section "<a
                                                                                href="#contact">HOW CAN YOU CONTACT US ABOUT THIS
                                                                                NOTICE?</a>" below.</div>
                                                                            <div>We will consider and act upon any request in
                                                                                accordance with applicable data protection laws.</div>
                                                                            <div>If you are located in the EEA or UK and you believe
                                                                                we are unlawfully processing your personal information,
                                                                                you also have the right to complain to your <a
                                                                                href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">Member
                                                                                State data protection authority</a> or&nbsp;<a
                                                                                href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/">UK
                                                                                data protection authority</a>.</div>
                                                                            <div>If you are located in Switzerland, you may contact
                                                                                the <a
                                                                                href="https://www.edoeb.admin.ch/edoeb/en/home.html">Federal
                                                                                Data Protection and Information Commissioner</a>.
                                                                            </div>
                                                                            <div class="pt-3 pb-3"><strong><u>Withdrawing your consent:</u></strong> If
                                                                                we are relying on your consent to process your personal
                                                                                information, which may be express and/or implied consent
                                                                                depending on the applicable law, you have the right to
                                                                                withdraw your consent at any time. You can withdraw your
                                                                                consent at any time by contacting us by using the
                                                                                contact details provided in the section "<a
                                                                                href="#contact">HOW CAN YOU CONTACT US ABOUT THIS
                                                                                NOTICE?</a>" below.</div>
                                                                            <div>However, please note that this will not affect the
                                                                                lawfulness of the processing before its withdrawal nor,
                                                                                when applicable law allows, will it affect the
                                                                                processing of your personal information conducted in
                                                                                reliance on lawful processing grounds other than
                                                                                consent.</div>
                                                                            <div class="pt-3 pb-3"><strong>Account Information</strong></div>
                                                                            <div>If you would at any time like to review or change the
                                                                                information in your account or terminate your account,
                                                                                you can:</div>
                                                                            <ul>
                                                                                <li>Log in to your account settings and update your user
                                                                                account.</li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li>Contact us using the contact information provided.
                                                                                </li>
                                                                            </ul>
                                                                            <div>Upon your request to terminate your account, we will
                                                                                deactivate or delete your account and information from
                                                                                our active databases. However, we may retain some
                                                                                information in our files to prevent fraud, troubleshoot
                                                                                problems, assist with any investigations, enforce our
                                                                                legal terms and/or comply with applicable legal
                                                                                requirements.</div>
                                                                            <div>If you have questions or comments about your privacy
                                                                                rights, you may email us at support@ezbudget.app.</div>
                                                                            <div class="pt-5" id="DNT"><strong>9. CONTROLS FOR DO-NOT-TRACK
                                                                                FEATURES</strong></div>
                                                                            <div>Most web browsers and some mobile operating systems
                                                                                and mobile applications include a Do-Not-Track ("DNT")
                                                                                feature or setting you can activate to signal your
                                                                                privacy preference not to have data about your online
                                                                                browsing activities monitored and collected. At this
                                                                                stage no uniform technology standard for recognizing and
                                                                                implementing DNT signals has been finalized. As such, we
                                                                                do not currently respond to DNT browser signals or any
                                                                                other mechanism that automatically communicates your
                                                                                choice not to be tracked online. If a standard for
                                                                                online tracking is adopted that we must follow in the
                                                                                future, we will inform you about that practice in a
                                                                                revised version of this privacy notice.</div>
                                                                            <div class="pt-5" id="uslaws"><strong>10. DO UNITED STATES RESIDENTS HAVE SPECIFIC
                                                                                PRIVACY RIGHTS?</strong></div>
                                                                            <div class="pt-3 pb-3"><strong><em>In
                                                                                    Short:&nbsp;</em></strong><em>I</em><em>f you are a
                                                                                resident of California, Virginia, Colorado,
                                                                                Connecticut, you are granted specific rights regarding
                                                                                access to your personal information.</em></div>
                                                                            <div class="pt-3 pb-3"><strong>What categories of personal information do we
                                                                                collect?</strong></div>
                                                                            <div>We have collected the following categories of
                                                                                personal information in the past twelve (12) months:
                                                                            </div>
                                                                            <table>
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td><strong>Category</strong></td>
                                                                                    <td><strong>Examples</strong></td>
                                                                                    <td><strong>Collected</strong></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                    <div>A. Identifiers</div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>Contact details, such as real name, alias,
                                                                                        postal address, telephone or mobile contact
                                                                                        number, unique personal identifier, online
                                                                                        identifier, Internet Protocol address, email
                                                                                        address, and account name</div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>YES</div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                    <div>B. Protected classification characteristics
                                                                                        under state or federal law</div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>Gender and date of birth</div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>NO</div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                    <div>C. Commercial information</div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>Transaction information, purchase history,
                                                                                        financial details, and payment information</div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>NO</div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                    <div>D. Biometric information</div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>Fingerprints and voiceprints</div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>YES</div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                    <div>E. Internet or other similar network activity
                                                                                    </div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>Browsing history, search history, online
                                                                                        behavior, interest data, and interactions with
                                                                                        our and other websites, applications, systems,
                                                                                        and advertisements</div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>NO</div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                    <div>F. Geolocation data</div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>Device location</div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>NO</div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                    <div>G. Audio, electronic, visual, thermal,
                                                                                        olfactory, or similar information</div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>Images and audio, video or call recordings
                                                                                        created in connection with our business
                                                                                        activities</div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>NO</div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                    <div>H. Professional or employment-related
                                                                                        information</div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>Business contact details in order to provide
                                                                                        you our Services at a business level or job
                                                                                        title, work history, and professional
                                                                                        qualifications if you apply for a job with us
                                                                                    </div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>NO</div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                    <div>I. Education Information</div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>Student records and directory information
                                                                                    </div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>NO</div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                    <div>J. Inferences drawn from collected personal
                                                                                        information</div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>Inferences drawn from any of the collected
                                                                                        personal information listed above to create a
                                                                                        profile or summary about, for example, an
                                                                                        individual&rsquo;s preferences and
                                                                                        characteristics</div>
                                                                                    </td>
                                                                                    <td>
                                                                                    <div>NO</div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>K. Sensitive personal information</td>
                                                                                    <td>Biometric data and account login information
                                                                                    </td>
                                                                                    <td><br />YES<br /><br /></td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <table>
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td>L. Personal information as defined in the
                                                                                    California Customer Records statute</td>
                                                                                    <td>Name, contact information, education,
                                                                                    employment, employment history, and financial
                                                                                    information</td>
                                                                                    <td>
                                                                                    <div>&nbsp;NO</div>
                                                                                    </td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <div>We will use and retain the collected personal
                                                                                information as needed to provide the Services or for:
                                                                            </div>
                                                                            <ul>
                                                                                <li>A. Identifiers - As long as the user has an account
                                                                                with us</li>
                                                                            </ul>
                                                                            <div>
                                                                                <div>
                                                                                <ul>
                                                                                    <li>D. Biometric information - As long as the user
                                                                                    has an account with us</li>
                                                                                </ul>
                                                                                <div>
                                                                                    <div>
                                                                                    <div>
                                                                                        <div>
                                                                                        <div>
                                                                                            <div>
                                                                                            <ul>
                                                                                                <li>K. Sensitive personal information -
                                                                                                As long as the user has an account
                                                                                                with us</li>
                                                                                            </ul>
                                                                                            <div>
                                                                                                <div>Sensitive personal information may
                                                                                                be used, or disclosed to a service
                                                                                                provider or contractor, for
                                                                                                additional, specified purposes. You
                                                                                                have the right to limit the use or
                                                                                                disclosure of your sensitive personal
                                                                                                information.</div>
                                                                                                <div>We may also collect other personal
                                                                                                information outside of these
                                                                                                categories through instances where you
                                                                                                interact with us in person, online, or
                                                                                                by phone or mail in the context of:
                                                                                                </div>
                                                                                                <ul>
                                                                                                <li>Receiving help through our
                                                                                                    customer support channels;</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>Participation in customer surveys
                                                                                                    or contests; and</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>Facilitation in the delivery of
                                                                                                    our Services and to respond to your
                                                                                                    inquiries.</li>
                                                                                                </ul>
                                                                                                <div class="pt-3 pb-3"><strong>How do we use and share
                                                                                                    your personal information?</strong>
                                                                                                </div>
                                                                                                <div>Learn about how we use your
                                                                                                personal information in the section,
                                                                                                "<a href="#infouse">HOW DO WE PROCESS
                                                                                                    YOUR INFORMATION?</a>"</div>
                                                                                                <div>More information about our data
                                                                                                collection and sharing practices can
                                                                                                be found in this privacy notice.</div>
                                                                                                <div class="pt-3 pb-3"><strong>Will your information be
                                                                                                    shared with anyone else?</strong>
                                                                                                </div>
                                                                                                <div>We may disclose your personal
                                                                                                information with our service providers
                                                                                                pursuant to a written contract between
                                                                                                us and each service provider. Learn
                                                                                                more about who we disclose personal
                                                                                                information to in the section, "<a
                                                                                                    href="#whoshare">WHEN AND WITH WHOM
                                                                                                    DO WE SHARE YOUR PERSONAL
                                                                                                    INFORMATION?</a>"</div>
                                                                                                <div>We may use your personal
                                                                                                information for our own business
                                                                                                purposes, such as for undertaking
                                                                                                internal research for technological
                                                                                                development and demonstration. This is
                                                                                                not considered to be "selling" of your
                                                                                                personal information.</div>
                                                                                                <div>We have not disclosed, sold, or
                                                                                                shared any personal information to
                                                                                                third parties for a business or
                                                                                                commercial purpose in the preceding
                                                                                                twelve (12) months. We will not sell
                                                                                                or share personal information in the
                                                                                                future belonging to website visitors,
                                                                                                users, and other consumers.</div>
                                                                                                <div class="pt-3 pb-3"><strong>California
                                                                                                    Residents</strong></div>
                                                                                                <div>California Civil Code Section
                                                                                                1798.83, also known as the "Shine The
                                                                                                Light" law, permits our users who are
                                                                                                California residents to request and
                                                                                                obtain from us, once a year and free
                                                                                                of charge, information about
                                                                                                categories of personal information (if
                                                                                                any) we disclosed to third parties for
                                                                                                direct marketing purposes and the
                                                                                                names and addresses of all third
                                                                                                parties with which we shared personal
                                                                                                information in the immediately
                                                                                                preceding calendar year. If you are a
                                                                                                California resident and would like to
                                                                                                make such a request, please submit
                                                                                                your request in writing to us using
                                                                                                the contact information provided
                                                                                                below.</div>
                                                                                                <div>If you are under 18 years of age,
                                                                                                reside in California, and have a
                                                                                                registered account with the Services,
                                                                                                you have the right to request removal
                                                                                                of unwanted data that you publicly
                                                                                                post on the Services. To request
                                                                                                removal of such data, please contact
                                                                                                us using the contact information
                                                                                                provided below and include the email
                                                                                                address associated with your account
                                                                                                and a statement that you reside in
                                                                                                California. We will make sure the data
                                                                                                is not publicly displayed on the
                                                                                                Services, but please be aware that the
                                                                                                data may not be completely or
                                                                                                comprehensively removed from all our
                                                                                                systems (e.g., backups, etc.).</div>
                                                                                                <div class="pt-3 pb-3"><strong><u>CCPA Privacy
                                                                                                    Notice</u></strong></div>
                                                                                                <div>The California Code of Regulations
                                                                                                defines a "resident" as:</div>
                                                                                                <div>(1) every individual who is in the
                                                                                                State of California for other than a
                                                                                                temporary or transitory purpose and
                                                                                                </div>
                                                                                                <div>(2) every individual who is
                                                                                                domiciled in the State of California
                                                                                                who is outside of the State of
                                                                                                California for a temporary or
                                                                                                transitory purpose</div>
                                                                                                <div>All other individuals are defined
                                                                                                as "non-residents."</div>
                                                                                                <div>If this definition of
                                                                                                "resident"&nbsp;applies to you, we
                                                                                                must adhere to certain rights and
                                                                                                obligations regarding your personal
                                                                                                information.</div>
                                                                                                <div class="pt-3 pb-3"><strong>Your rights with respect to
                                                                                                    your personal data</strong></div>
                                                                                                <div><u>Right to request deletion of the
                                                                                                    data &mdash; Request to delete</u>
                                                                                                </div>
                                                                                                <div>You can ask for the deletion of
                                                                                                your personal information. If you ask
                                                                                                us to delete your personal
                                                                                                information, we will respect your
                                                                                                request and delete your personal
                                                                                                information, subject to certain
                                                                                                exceptions provided by law, such as
                                                                                                (but not limited to) the exercise by
                                                                                                another consumer of his or her right
                                                                                                to free speech, our compliance
                                                                                                requirements resulting from a legal
                                                                                                obligation, or any processing that may
                                                                                                be required to protect against illegal
                                                                                                activities.</div>
                                                                                                <div><u>Right to be informed &mdash;
                                                                                                    Request to know</u></div>
                                                                                                <div>Depending on the circumstances, you
                                                                                                have a right to know:</div>
                                                                                                <ul>
                                                                                                <li>whether we collect and use your
                                                                                                    personal information;</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>the categories of personal
                                                                                                    information that we collect;</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>the purposes for which the
                                                                                                    collected personal information is
                                                                                                    used;</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>whether we sell or share personal
                                                                                                    information to third parties;</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>the categories of personal
                                                                                                    information that we sold, shared, or
                                                                                                    disclosed for a business purpose;
                                                                                                </li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>the categories of third parties to
                                                                                                    whom the personal information was
                                                                                                    sold, shared, or disclosed for a
                                                                                                    business purpose;</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>the business or commercial purpose
                                                                                                    for collecting, selling, or sharing
                                                                                                    personal information; and</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>the specific pieces of personal
                                                                                                    information we collected about you.
                                                                                                </li>
                                                                                                </ul>
                                                                                                <div>In accordance with applicable law,
                                                                                                we are not obligated to provide or
                                                                                                delete consumer information that is
                                                                                                de-identified in response to a
                                                                                                consumer request or to re-identify
                                                                                                individual data to verify a consumer
                                                                                                request.</div>
                                                                                                <div><u>Right to Non-Discrimination for
                                                                                                    the Exercise of a Consumer&rsquo;s
                                                                                                    Privacy Rights</u></div>
                                                                                                <div>We will not discriminate against
                                                                                                you if you exercise your privacy
                                                                                                rights.</div>
                                                                                                <div><u>Right to Limit Use and
                                                                                                    Disclosure of Sensitive Personal
                                                                                                    Information</u></div>
                                                                                                <div>If the business collects any of the
                                                                                                following:</div>
                                                                                                <ul>
                                                                                                <li>social security information,
                                                                                                    drivers' licenses, state ID cards,
                                                                                                    passport numbers</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>account login information</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>credit card numbers, financial
                                                                                                    account information, or credentials
                                                                                                    allowing access to such accounts
                                                                                                </li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>precise geolocation</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>racial or ethnic origin, religious
                                                                                                    or philosophical beliefs, union
                                                                                                    membership</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>the contents of email and text,
                                                                                                    unless the business is the intended
                                                                                                    recipient of the communication</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>genetic data, biometric data, and
                                                                                                    health data</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>data concerning sexual orientation
                                                                                                    and sex life</li>
                                                                                                </ul>
                                                                                                <div>you have the right to direct that
                                                                                                business to limit its use of your
                                                                                                sensitive personal information to that
                                                                                                use which is necessary to perform the
                                                                                                Services.</div>
                                                                                                <div>Once a business receives your
                                                                                                request, they are no longer allowed to
                                                                                                use or disclose your sensitive
                                                                                                personal information for any other
                                                                                                purpose unless you provide consent for
                                                                                                the use or disclosure of sensitive
                                                                                                personal information for additional
                                                                                                purposes.</div>
                                                                                                <div>Please note that sensitive personal
                                                                                                information that is collected or
                                                                                                processed without the purpose of
                                                                                                inferring characteristics about a
                                                                                                consumer is not covered by this right,
                                                                                                as well as the publicly available
                                                                                                information.</div>
                                                                                                <div>To exercise your right to limit use
                                                                                                and disclosure of sensitive personal
                                                                                                information, please email
                                                                                                support@ezbudget.app or submit a <a
                                                                                                    href="https://app.termly.io/notify/3e907a43-7fe1-4dea-be98-2284a225101e">data
                                                                                                    subject access request</a>.</div>
                                                                                                <div><u>Verification process</u></div>
                                                                                                <div>Upon receiving your request, we
                                                                                                will need to verify your identity to
                                                                                                determine you are the same person
                                                                                                about whom we have the information in
                                                                                                our system. These verification efforts
                                                                                                require us to ask you to provide
                                                                                                information so that we can match it
                                                                                                with information you have previously
                                                                                                provided us. For instance, depending
                                                                                                on the type of request you submit, we
                                                                                                may ask you to provide certain
                                                                                                information so that we can match the
                                                                                                information you provide with the
                                                                                                information we already have on file,
                                                                                                or we may contact you through a
                                                                                                communication method (e.g., phone or
                                                                                                email) that you have previously
                                                                                                provided to us. We may also use other
                                                                                                verification methods as the
                                                                                                circumstances dictate.</div>
                                                                                                <div>We will only use personal
                                                                                                information provided in your request
                                                                                                to verify your identity or authority
                                                                                                to make the request. To the extent
                                                                                                possible, we will avoid requesting
                                                                                                additional information from you for
                                                                                                the purposes of verification. However,
                                                                                                if we cannot verify your identity from
                                                                                                the information already maintained by
                                                                                                us, we may request that you provide
                                                                                                additional information for the
                                                                                                purposes of verifying your identity
                                                                                                and for security or fraud-prevention
                                                                                                purposes. We will delete such
                                                                                                additionally provided information as
                                                                                                soon as we finish verifying you.</div>
                                                                                                <div><u>Other privacy rights</u></div>
                                                                                                <ul>
                                                                                                <li>You may object to the processing
                                                                                                    of your personal information.</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>You may request correction of your
                                                                                                    personal data if it is incorrect or
                                                                                                    no longer relevant, or ask to
                                                                                                    restrict the processing of the
                                                                                                    information.</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>You can designate an authorized
                                                                                                    agent to make a request under the
                                                                                                    CCPA on your behalf. We may deny a
                                                                                                    request from an authorized agent
                                                                                                    that does not submit proof that they
                                                                                                    have been validly authorized to act
                                                                                                    on your behalf in accordance with
                                                                                                    the CCPA.</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>You may request to opt out from
                                                                                                    future selling or sharing of your
                                                                                                    personal information to third
                                                                                                    parties. Upon receiving an opt-out
                                                                                                    request, we will act upon the
                                                                                                    request as soon as feasibly
                                                                                                    possible, but no later than fifteen
                                                                                                    (15) days from the date of the
                                                                                                    request submission.</li>
                                                                                                </ul>
                                                                                                <div>To exercise these rights, you can
                                                                                                contact us by submitting a&nbsp;<a
                                                                                                    href="https://app.termly.io/notify/3e907a43-7fe1-4dea-be98-2284a225101e">data
                                                                                                    subject access request</a>, by email
                                                                                                at support@ezbudget.app, or by
                                                                                                referring to the contact details at
                                                                                                the bottom of this document. If you
                                                                                                have a complaint about how we handle
                                                                                                your data, we would like to hear from
                                                                                                you.</div>
                                                                                                <div class="pt-3 pb-3"><strong>Virginia Residents</strong>
                                                                                                </div>
                                                                                                <div>Under the Virginia Consumer Data
                                                                                                Protection Act (VCDPA):</div>
                                                                                                <div>"Consumer" means a natural person
                                                                                                who is a resident of the Commonwealth
                                                                                                acting only in an individual or
                                                                                                household context. It does not include
                                                                                                a natural person acting in a
                                                                                                commercial or employment context.
                                                                                                </div>
                                                                                                <div>"Personal data" means any
                                                                                                information that is linked or
                                                                                                reasonably linkable to an identified
                                                                                                or identifiable natural person.
                                                                                                "Personal data" does not include
                                                                                                de-identified data or publicly
                                                                                                available information.</div>
                                                                                                <div>"Sale of personal data" means the
                                                                                                exchange of personal data for monetary
                                                                                                consideration.</div>
                                                                                                <div>If this definition "consumer"
                                                                                                applies to you, we must adhere to
                                                                                                certain rights and obligations
                                                                                                regarding your personal data.</div>
                                                                                                <div><u>Your rights with respect to your
                                                                                                    personal data</u></div>
                                                                                                <ul>
                                                                                                <li>Right to be informed whether or
                                                                                                    not we are processing your personal
                                                                                                    data</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>Right to access your personal data
                                                                                                </li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>Right to correct inaccuracies in
                                                                                                    your personal data</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>Right to request deletion of your
                                                                                                    personal data</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>Right to obtain a copy of the
                                                                                                    personal data you previously shared
                                                                                                    with us</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>Right to opt out of the processing
                                                                                                    of your personal data if it is used
                                                                                                    for targeted advertising, the sale
                                                                                                    of personal data, or profiling in
                                                                                                    furtherance of decisions that
                                                                                                    produce legal or similarly
                                                                                                    significant effects ("profiling")
                                                                                                </li>
                                                                                                </ul>
                                                                                                <div><u>Exercise your rights provided
                                                                                                    under the Virginia VCDPA</u></div>
                                                                                                <div>You may contact us by email
                                                                                                support@ezbudget.app or submit a <a
                                                                                                    href="https://app.termly.io/notify/3e907a43-7fe1-4dea-be98-2284a225101e">data
                                                                                                    subject access request</a>.</div>
                                                                                                <div>If you are using an authorized
                                                                                                agent to exercise you rights, we may
                                                                                                deny a request if the authorized agent
                                                                                                does not submit proof that they have
                                                                                                been validly authorized to act on your
                                                                                                behalf.</div>
                                                                                                <div><u>Verification process</u></div>
                                                                                                <div>We may request that you provide
                                                                                                additional information reasonably
                                                                                                necessary to verify you and your
                                                                                                consumer's request. If you submit the
                                                                                                request through an authorized agent,
                                                                                                we may need to collect additional
                                                                                                information to verify your identity
                                                                                                before processing your request.</div>
                                                                                                <div>Upon receiving your request, we
                                                                                                will respond without undue delay, but
                                                                                                in all cases, within forty-five (45)
                                                                                                days of receipt. The response period
                                                                                                may be extended once by forty-five
                                                                                                (45) additional days when reasonably
                                                                                                necessary. We will inform you of any
                                                                                                such extension within the initial
                                                                                                45-day response period, together with
                                                                                                the reason for the extension.</div>
                                                                                                <div><u>Right to appeal</u></div>
                                                                                                <div>If we decline to take action
                                                                                                regarding your request, we will inform
                                                                                                you of our decision and reasoning
                                                                                                behind it.&nbsp;If you wish to appeal
                                                                                                our decision, please email us at
                                                                                                support@ezbudget.app. Within sixty
                                                                                                (60) days of receipt of an appeal, we
                                                                                                will inform you in writing of any
                                                                                                action taken or not taken in response
                                                                                                to the appeal, including a written
                                                                                                explanation of the reasons for the
                                                                                                decisions. If your appeal if denied,
                                                                                                you may contact the&nbsp;<a
                                                                                                    href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint">Attorney
                                                                                                    General to submit a complaint</a>.
                                                                                                </div>
                                                                                                <div class="pt-3 pb-3"><strong>Colorado Residents</strong>
                                                                                                </div>
                                                                                                <div>This section applies only to
                                                                                                Colorado residents. Under the Colorado
                                                                                                Privacy Act (CPA), you have the rights
                                                                                                listed below. However, these rights
                                                                                                are not absolute, and in certain cases
                                                                                                we may decline your request as
                                                                                                permitted by law.</div>
                                                                                                <ul>
                                                                                                <li>Right to be informed whether or
                                                                                                    not we are processing your personal
                                                                                                    data</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>Right to access your personal data
                                                                                                </li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>Right to correct inaccuracies in
                                                                                                    your persona data</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>Right to request deletion of your
                                                                                                    personal data&nbsp;</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>Right to obtain a copy of the
                                                                                                    personal data you previously shared
                                                                                                    with us</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>Right to opt out of the processing
                                                                                                    of your personal data if it is used
                                                                                                    for targeted advertising, the sale
                                                                                                    of personal data, or profiling in
                                                                                                    furtherance of decisions that
                                                                                                    product legal or similarly
                                                                                                    significant effects ("profiling")
                                                                                                </li>
                                                                                                </ul>
                                                                                                <div>To submit a request to exercise
                                                                                                these rights&nbsp;described above,
                                                                                                please email support@ezbudget.app or
                                                                                                submit a&nbsp;<a
                                                                                                    href="https://app.termly.io/notify/3e907a43-7fe1-4dea-be98-2284a225101e">data
                                                                                                    subject access request</a>.</div>
                                                                                                <div>If we decline to take action
                                                                                                regarding your request and you wish to
                                                                                                appeal our decision, please email us
                                                                                                at support@ezbudget.app. Within
                                                                                                forty-five (45) days of receipt of an
                                                                                                appeal, we will information you in
                                                                                                writing of any action taken or not
                                                                                                taken in response to the appeal,
                                                                                                including a written explanation of the
                                                                                                reasons for the decisions.</div>
                                                                                                <div class="pt-3 pb-3"><strong>Connecticut
                                                                                                    Residents</strong></div>
                                                                                                <div>This section applies to Connecticut
                                                                                                residents. Under the Connecticut Data
                                                                                                Privacy Act (CTDPA), you have the
                                                                                                rights listed below. However, these
                                                                                                rights are not absolute, and in
                                                                                                certain cases we may decline your
                                                                                                request as permitted by law.</div>
                                                                                                <ul>
                                                                                                <li>Right to be informed whether or
                                                                                                    not we are processing your personal
                                                                                                    data</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>Right to access your personal data
                                                                                                </li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>Right to correct inaccuracies in
                                                                                                    your personal data</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>Right to request deletion of your
                                                                                                    personal data</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>Right to obtain a copy of the
                                                                                                    personal data you previously shared
                                                                                                    with us</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>Right to opt out of the processing
                                                                                                    of your personal data if it is used
                                                                                                    from targeted advertising, the sale
                                                                                                    of personal data, or profiling in
                                                                                                    furtherance of decisions that
                                                                                                    product legal or similarly
                                                                                                    significant effects ("profiling")
                                                                                                </li>
                                                                                                </ul>
                                                                                                <div>To submit a request to exercise
                                                                                                these rights described above, please
                                                                                                email support@ezbudget.app or submit
                                                                                                a&nbsp;<a
                                                                                                    href="https://app.termly.io/notify/3e907a43-7fe1-4dea-be98-2284a225101e">data
                                                                                                    subject access request</a>.</div>
                                                                                                <div>If we decline to take action
                                                                                                regarding your request and you wish to
                                                                                                appeal our decision, please email us
                                                                                                at support@ezbudget.app. Within sixty
                                                                                                (60) days of receipt of an appeal, we
                                                                                                will information you in writing of any
                                                                                                action taken or not taken in response
                                                                                                to the appeal, including a written
                                                                                                explanation of the reasons for the
                                                                                                decisions.</div>
                                                                                                <div class="pt-5" id="otherlaws"><strong>11. DO OTHER REGIONS HAVE
                                                                                                    SPECIFIC PRIVACY RIGHTS?</strong>
                                                                                                </div>
                                                                                                <div class="pt-3 pb-3"><strong><em>In
                                                                                                    Short:</em></strong><em>&nbsp;You
                                                                                                    may have additional rights based on
                                                                                                    the country you reside in.</em>
                                                                                                </div>
                                                                                                <div class="pt-3 pb-3"><strong>Australia</strong>
                                                                                                <strong>and&nbsp;</strong><strong>New
                                                                                                    Zealand</strong></div>
                                                                                                <div>We collect and process your
                                                                                                personal information under the
                                                                                                obligations and conditions set by
                                                                                                Australia's Privacy Act 1988 and New
                                                                                                Zealand's Privacy Act 2020 (Privacy
                                                                                                Act).</div>
                                                                                                <div>This privacy notice satisfies the
                                                                                                notice requirements defined in both
                                                                                                Privacy Acts, in particular: what
                                                                                                personal information we collect from
                                                                                                you, from which sources, for which
                                                                                                purposes, and other recipients of your
                                                                                                personal information.</div>
                                                                                                <div>If you do not wish to provide the
                                                                                                personal information necessary to
                                                                                                fulfill their applicable purpose, it
                                                                                                may affect our ability to provide our
                                                                                                Services, in particular:</div>
                                                                                                <ul>
                                                                                                <li>offer you the products or services
                                                                                                    that you want</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>manage your account with us</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>respond to or help with your
                                                                                                    requests</li>
                                                                                                </ul>
                                                                                                <ul>
                                                                                                <li>confirm your identity and protect
                                                                                                    your account</li>
                                                                                                </ul>
                                                                                                <div>At any time, you have the right to
                                                                                                request access to or correction of
                                                                                                your personal information. You can
                                                                                                make such a request by contacting us
                                                                                                by using the contact details provided
                                                                                                in the section "<a href="#request">HOW
                                                                                                    CAN YOU REVIEW, UPDATE, OR DELETE
                                                                                                    THE DATA WE COLLECT FROM YOU?</a>"
                                                                                                </div>
                                                                                                <div>If you believe we are unlawfully
                                                                                                processing your personal information,
                                                                                                you have the right to submit a
                                                                                                complaint about a breach of the
                                                                                                Australian Privacy Principles to the
                                                                                                <a
                                                                                                    href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us">Office
                                                                                                    of the Australian Information
                                                                                                    Commissioner</a> and for a breach of
                                                                                                the New Zealand's Privacy Principles
                                                                                                to the <a
                                                                                                    href="https://www.privacy.org.nz/your-rights/making-a-complaint/">Office
                                                                                                    of New Zealand Privacy
                                                                                                    Commissioner</a>.</div>
                                                                                                <div class="pt-3 pb-3"><strong>Republic of South
                                                                                                    Africa</strong></div>
                                                                                                <div>At any time, you have the right to
                                                                                                request access to or correction of
                                                                                                your personal information. You can
                                                                                                make such a request by contacting us
                                                                                                by using the contact details provided
                                                                                                in the section "<a href="#request">HOW
                                                                                                    CAN YOU REVIEW, UPDATE, OR DELETE
                                                                                                    THE DATA WE COLLECT FROM YOU?</a>"
                                                                                                </div>
                                                                                                <div>If you are unsatisfied with the
                                                                                                manner in which we address any
                                                                                                complaint with regard to our
                                                                                                processing of personal information,
                                                                                                you can contact the office of the
                                                                                                regulator, the details of which are:
                                                                                                </div>
                                                                                                <div><a
                                                                                                    href="https://inforegulator.org.za/">The
                                                                                                    Information Regulator (South
                                                                                                    Africa)</a></div>
                                                                                                <div>General enquiries:
                                                                                                enquiries@inforegulator.org.za</div>
                                                                                                <div>Complaints (complete POPIA/PAIA
                                                                                                form 5):
                                                                                                PAIAComplaints@inforegualtor.org.za
                                                                                                &amp;&nbsp;POPIAComplaints@inforegulator.org.za
                                                                                                </div>
                                                                                                <div class="pt-5" id="policyupdates"><strong>12. DO WE MAKE UPDATES TO
                                                                                                    THIS NOTICE?</strong></div>
                                                                                                <div class="pt-3 pb-3"><em><strong>In
                                                                                                    Short:&nbsp;</strong>Yes, we will
                                                                                                    update this notice as necessary to
                                                                                                    stay compliant with relevant
                                                                                                    laws.</em></div>
                                                                                                <div>We may update this privacy notice
                                                                                                from time to time. The updated version
                                                                                                will be indicated by an updated
                                                                                                "Revised" date and the updated version
                                                                                                will be effective as soon as it is
                                                                                                accessible. If we make material
                                                                                                changes to this privacy notice, we may
                                                                                                notify you either by prominently
                                                                                                posting a notice of such changes or by
                                                                                                directly sending you a notification.
                                                                                                We encourage you to review this
                                                                                                privacy notice frequently to be
                                                                                                informed of how we are protecting your
                                                                                                information.</div>
                                                                                                <div class="pt-5" id="contact"><strong>13. HOW CAN YOU CONTACT US
                                                                                                    ABOUT THIS NOTICE?</strong></div>
                                                                                                <div>If you have questions or comments
                                                                                                about this notice, you may email us at
                                                                                                support@ezbudget.app&nbsp;or contact
                                                                                                us by post at:</div>
                                                                                                <div>EZ Budget</div>
                                                                                                <div>49 N 400 W</div>
                                                                                                <div>STE 102</div>
                                                                                                <div>PROVO, UT 84601</div>
                                                                                                <div>United States</div>
                                                                                                <div class="pt-5" id="request"><strong>14. HOW CAN YOU REVIEW,
                                                                                                    UPDATE, OR DELETE THE DATA WE
                                                                                                    COLLECT FROM YOU?</strong></div>
                                                                                                <div>Based on the applicable laws of
                                                                                                your country, you may have the right
                                                                                                to request access to the personal
                                                                                                information we collect from you,
                                                                                                change that information, or delete it.
                                                                                                To request to review, update, or
                                                                                                delete your personal information,
                                                                                                please fill out and submit a&nbsp;<a
                                                                                                    href="https://app.termly.io/notify/3e907a43-7fe1-4dea-be98-2284a225101e">data
                                                                                                    subject access request</a>.</div>
                                                                                            </div>
                                                                                            <div class="pt-4">This privacy policy was created using
                                                                                                Termly's <a
                                                                                                href="https://termly.io/products/privacy-policy-generator/">Privacy
                                                                                                Policy Generator</a>.</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    </div>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;