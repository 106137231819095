import { useState } from "react";
import { finishPasswordChange } from "../utils/BackendAccessLayer";

const ChangePasswordForm = ({ visible, code }) => {

    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [newPass, setNewPass] = useState("");
    const [confNewPass, setConfNewPass] = useState("");
    const [response, setResponse] = useState("");

    const submitForm = (event) => {
        event.preventDefault();
        finishPasswordChange(newPass, confNewPass, code, setResponse, setError, setSubmitted);
    };

    const clearMessage = (event) => {
        event.preventDefault();
        setResponse("");
        setError(false);
    };

    return (
        <div>
            { (!visible) ? <div /> :
            <div class="form-wrapper">
                <form onSubmit={submitForm} onChange={clearMessage}>
                    <div class="form-floating mb-3">
                        <input 
                            type="password" 
                            class="form-control" 
                            id="new_pass" 
                            onChange={e => setNewPass(e.target.value)}
                        />
                        <label for="new_pass" class="form-label">New Password</label>
                    </div>
                    <div class="form-floating mb-3">
                        <input 
                            type="password" 
                            class="form-control" 
                            id="conf_new_pass" 
                            onChange={e => setConfNewPass(e.target.value)}
                        />
                        <label for="conf_new_pass" class="form-label">Re-Type New Password</label>
                    </div>
                    {
                        (submitted) ? <div /> :
                        <div class="pt-3">
                            <button type="submit" class="btn btn-primary">Change</button>
                        </div>
                    }
                    <div class="pt-3" style={{
                        display: (response !== "") ? 'block' : 'none'
                    }}>
                        {
                        (!error) ? 
                        <div>
                            <div class="alert alert-success" role="alert">
                                { response }
                            </div>
                        </div> :
                        <div>
                            <div class="alert alert-danger" role="alert">
                                { response }
                            </div>
                        </div>
                        }
                    </div>
                </form>
            </div>
            }
        </div>
    );
};

export default ChangePasswordForm;