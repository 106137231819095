import './bootstrap/bootstrap.min.css';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import Verify from './pages/Verify';
import NotFound from './pages/NotFound';
import ResetPassword from './pages/ResetPassword';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AccountDeletion from "./pages/AccountDeletion";

const App = () => {
  return (
    <Router>
		<Routes>
			<Route path="/">
				<Route index element={<Home />} />
				<Route path="register" element={<Register />} />
				<Route exact path="forgot" element={<ForgotPassword />} />
				<Route exact path="password/change/:code" element={<ResetPassword />} />
				<Route exact path="verify/:id" element={<Verify />} />
				<Route exact path="privacy" element={<PrivacyPolicy />} />
				<Route exact path="account/delete" element={<AccountDeletion />} />
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	</Router>
  );
};

export default App;
