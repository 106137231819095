import React, { useState } from "react";
import { registerUser, verifyToken } from "../utils/BackendAccessLayer";
import ReCAPTCHA from "react-google-recaptcha";

const RegisterForm = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confpassword, setConfPassword] = useState('');
    const [response, setResponse] = useState("");
    const [error, setError] = useState(false);
    const [verified, setVerified] = useState(false);
    const recaptchaRef = React.createRef();

    const submitForm = (event) => {
        event.preventDefault();
        registerUser(username, email, password, confpassword, setResponse, setError);
    };

    const clearMessage = (event) => {
        event.preventDefault();
        setResponse("");
        setError(false);
    };

    return (
        <div class="form-wrapper">
            <div class="pb-3">
                <h3 class="text-center">Sign up</h3>
            </div>
            <form onSubmit={submitForm} onChange={clearMessage}>
                <div class="form-floating mb-3">
                    <input 
                        type="text" 
                        class="form-control" 
                        id="username" 
                        onChange={e => setUsername(e.target.value)}
                    />
                    <label for="username" class="form-label">Username</label>
                </div>
                <div class="form-floating mb-3">
                    <input 
                        type="email" 
                        class="form-control" 
                        id="email" 
                        onChange={e => setEmail(e.target.value)}
                    />
                    <label for="email" class="form-label">Email address</label>
                </div>
                <div class="form-floating mb-3">
                    <input 
                        type="password" 
                        class="form-control" 
                        id="password" 
                        onChange={e => setPassword(e.target.value)}
                    />
                    <label for="password" class="form-label">Password</label>
                </div>
                <div class="form-floating mb-3">
                    <input 
                        type="password" 
                        class="form-control" 
                        id="confpassword" 
                        onChange={e => setConfPassword(e.target.value)}
                    />
                    <label for="confpassword" class="form-label">Confirm Password</label>
                </div>
                <div>
                <div>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={"6Lf1VpYmAAAAAKzwEPx01R_fAHprxQhZuNhTRjEA"}
                        onChange={(response) => {
                            if(response == null)
                                setVerified(false);

                            verifyToken(response, setVerified, setError);
                        }}
                    />
                </div>
                </div>
                <div>
                    <p>By signing up, I certify that I am 18 years or older, and agree to the <a href="/privacy">Privacy Policy</a>.</p>
                </div>
                <div class="pt-3">
                    <button type="submit" class="btn btn-primary" disabled={!verified}>Sign Up</button>
                </div>
                <div class="pt-3" style={{
                    display: (response !== "") ? 'block' : 'none'
                }}>
                    {
                        (!error) ? 
                        <div>
                            <div class="alert alert-success" role="alert">
                                { response }
                            </div>
                        </div> :
                        <div>
                            <div class="alert alert-danger" role="alert">
                                { response }
                            </div>
                        </div>
                    }
                </div>
            </form>
        </div>
    );
};

export default RegisterForm;