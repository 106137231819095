import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { sendResetEmail, verifyToken } from "../utils/BackendAccessLayer";

const ResetCode = () => {
    const [id, setId] = useState("");
    const [response, setResponse] = useState("");
    const [error, setError] = useState(false);
    const [verified, setVerified] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const recaptchaRef = React.createRef();

    const submitForm = (event) => {
        event.preventDefault();
        sendResetEmail(id, setResponse, setError, setSubmitted);
    };

    const clearMessage = (event) => {
        event.preventDefault();
        setResponse("");
        setError(false);
    };

    return (
        <div class="form-wrapper">
            <form onSubmit={submitForm} onChange={clearMessage}>
                <div class="form-floating mb-3">
                    <input 
                        type="text" 
                        class="form-control" 
                        id="identifier" 
                        onChange={e => setId(e.target.value)}
                    />
                    <label for="identifier" class="form-label">Username or Email</label>
                </div>
                <div>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={"6Lf1VpYmAAAAAKzwEPx01R_fAHprxQhZuNhTRjEA"}
                        onChange={(response) => {
                            if(response == null)
                                setVerified(false);

                            verifyToken(response, setVerified, setError);
                        }}
                    />
                </div>
                {
                    (submitted) ? <div /> :
                    <div class="pt-3">
                        <button type="submit" class="btn btn-primary" disabled={!verified}>Send Reset Code</button>
                    </div>
                }
                <div class="pt-3" style={{
                    display: (response !== "") ? 'block' : 'none'
                }}>
                    {
                        (!error) ? 
                        <div>
                            <div class="alert alert-success" role="alert">
                                { response }
                            </div>
                        </div> :
                        <div>
                            <div class="alert alert-danger" role="alert">
                                { response }
                            </div>
                        </div>
                    }
                </div>
            </form>
        </div>
    );
};

export default ResetCode;