const Menu = () => {
    return (
        <div class="p-2 ps-4 ps-sm-5">
            <div style={{
                height: 40
            }}>

            </div>
        </div>
    );
};

export default Menu;