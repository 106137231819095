import { useEffect, useState } from "react";
import { authorizePasswordCode } from "../utils/BackendAccessLayer";

const AuthorizePasswordChangeCode = ({ code, onChange }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        authorizePasswordCode(code, onChange, setLoading);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            { (!loading) ? <div /> :
                <div class="d-flex justify-content-center align-items-center" style={{ height: 400 }}>
                    <div class="spinner-grow text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            }
        </div>
    );
};

export default AuthorizePasswordChangeCode;