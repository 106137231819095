
import Lottie from "react-lottie-player";
import lostLottie from "../assets/404.json";

const NotFound = () => {
    return (
        <div class="row mb-5">
            <div class="col-10 offset-1 col-sm-4 offset-sm-4" style={{
                marginTop: 200
            }}>
                <div>
                    <Lottie
                        loop={false}
                        animationData={lostLottie}
                        play
                    />
                </div>
                <div class="text-center">
                    <h2>Woops!</h2>
                    <h4>Looks like we couldn't find that page.</h4>
                    <p><a href="/">Find your way back here</a></p>
                </div>
            </div>
        </div>
    );
};

export default NotFound;