import { useState } from "react";
import { useParams } from "react-router-dom";
import AuthorizePasswordChangeCode from "../components/AuthorizePasswordChangeCode";
import ChangePasswordForm from "../components/ChangePasswordForm";

const ResetPassword = () => {
    const { code } = useParams();

    const [isAuthorized, setAuthorized] = useState(false);

    return (
        <div>
            <div class="row mt-5">
                <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                    <h2 class="text-center">Reset Password</h2>
                    <p class="text-center">Change your account password to a new one.</p>
                    <div>
                        <AuthorizePasswordChangeCode
                            code={code}
                            onChange={(value) => {
                                setAuthorized(value);
                            }} 
                        />
                    </div>
                    <div>
                        <ChangePasswordForm
                            visible={isAuthorized}
                            code={code}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;