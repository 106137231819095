import ResetCode from '../components/ResetCode';

const ForgotPassword = (props) => {
    /*
     * /api/user/password?action=init   Sends and email to user's email with a link
     * Body params: identifier
     * /api/user/password?action=auth   Clicking link will bring the user to new page where they can reset their password after successfully authenticating the code
     * Body params: code
     * /api/user/password?action=comp   Complete password change
     * Body params: code, password
     */
    return (
        <div>
            <div class="row mt-5">
                <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                    <h2 class="text-center">Reset Password</h2>
                    <p class="text-center">We'll send a password reset request to your email.</p>
                    <ResetCode />
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;